<template>
  <div class="columns is-marginless has-padding-50 is-multiline">
    <div class="column is-12">
      <card>
        <template slot="header">
          <p class="card-header-title">
            <strong>Reports</strong>
          </p>
        </template>
        <template slot="body">
          <p class="has-margin-bottom-100">
            Please select a report from the dropdown below. Note – reports can
            sometimes take a little while to generate if the function has to go
            through a cold start (up to 30 seconds).
          </p>
          <b-select
            v-model="selected"
            placeholder="Select a report"
            expanded
            :loading="isProcessing"
            :disabled="isProcessing"
          >
            <optgroup
              v-for="(groupOptions, group) in optionsByGroup"
              :key="group"
              :label="group"
            >
              <option
                v-for="(option, i) in groupOptions"
                :key="`option-${i}`"
                :value="option"
              >
                {{ option.label }}
              </option>
            </optgroup>
          </b-select>
        </template>
      </card>
    </div>
  </div>
</template>

<script>
import { downloadBlob } from "@src/services/fileutil";
export default {
  name: "AdminReports",
  data() {
    return {
      selected: null,
      isProcessing: false,
      options: [
        {
          group: "Contracts",
          label: "Export all active contracts",
          from: "onCall-export-allActiveContracts",
          as: "all-active-contracts"
        },
        {
          group: "Contracts",
          label: "Export contracts for Pipe",
          from: "onCall-export-contractsForPipe",
          as: "contracts-for-pipe"
        },
        {
          group: "Subscriptions",
          label: "Export all taxed subscription users",
          from: "onCall-export-taxedSubscriptionUsers",
          as: "taxed-susbcription-users"
        },
        {
          group: "Subscriptions",
          label: "Export all subscribed sites",
          from: "onCall-export-allSubscribedSites",
          as: "all-subscribed-sites"
        },
        {
          group: "Subscriptions",
          label: "Export all subscriptions",
          from: "onCall-export-allSubscriptions",
          as: "all-subscriptions"
        },
        {
          group: "Transactions",
          label: "Export all transactions",
          from: "onCall-export-allTransactions",
          as: "all-transactions"
        },
        {
          group: "Invoices",
          label: "Export all invoices",
          from: "onCall-export-allInvoices",
          as: "all-invoices"
        },
        {
          group: "Invoices",
          label: "Export all negative invoices",
          from: "onCall-export-allNegativeInvoices",
          as: "all-negative-invoices"
        },
        {
          group: "Invoices",
          label: "Export monthly invoice totals",
          from: "onCall-export-monthlyInvoiceTotals",
          as: "monthly-invoice-totals"
        },
        {
          group: "Marketing",
          label: "Export marketing subscribers",
          from: "onCall-export-allMarketingSubscribers",
          as: "all-marketing-subscribers"
        },
        {
          group: "Marketing",
          label: "Export all users (excluding reseller clients)",
          from: "onCall-export-allUsersExcludingResellerClients",
          as: "all-users-excluding-reseller-clients"
        },
        {
          group: "Promotions",
          label: "Export orders with promotions",
          from: "onCall-export-ordersPromotions",
          as: "all-orders-with-promotions"
        },
        {
          group: "Promotions",
          label: "Export promotions",
          from: "onCall-export-promotions",
          as: "all-promotions"
        },
        {
          group: "Orders",
          label: "Export orders",
          from: "onCall-export-orders",
          as: "all-orders"
        },
        {
          group: "Misc",
          label: "Users Lifetime Value",
          from: "onCall-export-usersLifetimeValue",
          as: "users-lifetime-value"
        },
        {
          group: "Misc",
          label: "Site Tasks Stats",
          from: "onCall-export-siteTasksStats",
          as: "site-tasks-stats"
        }
      ]
    };
  },
  computed: {
    optionsByGroup() {
      return this.$_.groupBy(this.options, "group");
    }
  },
  watch: {
    async selected(option) {
      if (!option) return;
      this.isProcessing = true;
      await this.export(option);
      this.$nextTick(() => {
        this.selected = null;
        this.isProcessing = false;
      });
    }
  },
  methods: {
    export({ from, as }) {
      return new Promise(resolve => {
        this.$functions()
          .httpsCallable(from, { timeout: 540000 })()
          .then(result => {
            var blob = new Blob([result.data.data], {
              type: "text/csv;charset=utf-8;"
            });
            const filename = `${as}-${this.$moment().format("YYYY-MM-DD")}.csv`;
            downloadBlob(blob, filename);
            this.$toast.open({
              message: `Report generated`
            });
            resolve();
          })
          .catch(error => {
            console.error(error);
            this.$toast.open({
              message: `Error generating '${as}' report`,
              type: "is-danger"
            });
            resolve();
          });
      });
    }
  }
};
</script>
